import React, { useEffect, useRef } from "react"
import { useStaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"
import { useTranslation } from "gatsby-plugin-react-i18next-nocookie"
import { useWindowSize } from "../hooks/windowsize"

import Styles from "../styles/modules/spaces.module.scss"

const Spaces = props => {
  const size = useWindowSize()

  const refs = useRef([])

  const { t } = useTranslation("about")

  const images = useStaticQuery(graphql`
    query {
      entrance: file(relativePath: { eq: "entrance.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 960) {
            ...GatsbyImageSharpFluid
          }
        }
      }

      kitchen: file(relativePath: { eq: "kitchen.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 960) {
            ...GatsbyImageSharpFluid
          }
        }
      }

      meeting: file(relativePath: { eq: "meeting.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 960) {
            ...GatsbyImageSharpFluid
          }
        }
      }

      view: file(relativePath: { eq: "view.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 960) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)

  useEffect(() => {
    const makeWidths = event => {
      if (size.width < 992) {
        return true
      }

      const even =
        [...event.target.parentElement.children].indexOf(event.target) % 2 === 1

      refs.current.map((el, i) => {
        if (even) {
          if ((i % 2 === 0) === even) {
            el.style.width = "25%"
          } else {
            el.style.width = "75%"
          }
        } else {
          if ((i % 2 === 1) === even) {
            el.style.width = "75%"
          } else {
            el.style.width = "25%"
          }
        }
        return true
      })
    }

    const resetWidths = () => {
      if (size.width < 992) {
        return true
      }
      refs.current.map(el => {
        el.style.width = "50%"
        return true
      })
    }

    refs.current.map((el, i) => {
      el.addEventListener("mouseenter", makeWidths)
      el.addEventListener("mouseleave", resetWidths)
      return true
    })

    const elemenets = refs.current
    return () => {
      elemenets.map((el, i) => {
        el.removeEventListener("mouseenter", makeWidths)
        el.removeEventListener("mouseleave", resetWidths)
        el.style.removeProperty("width")
        return true
      })
    }
  }, [size.width])

  const translations = t("rooms", { returnObjects: true })

  return (
    <div className={Styles.grid}>
      {translations.map((v, i) => {
        return (
          <div
            key={i}
            ref={ref =>
              ref && !refs.current.includes(ref) && refs.current.push(ref)
            }
            role="presentation"
            className={Styles.grid__item}
          >
            <div className={Styles.grid__item__texts}>
              <h3 className={Styles.grid__item__title}>
                {t("rooms." + i + ".title")}
              </h3>
              <ul className={Styles.grid__item__properties}>
                {t("rooms." + i + ".properties", { returnObjects: true }).map(
                  (k, l) => {
                    return (
                      <li key={l} className={Styles.grid__item__property}>
                        {k}
                      </li>
                    )
                  }
                )}
              </ul>
            </div>
            <Img
              className={Styles.grid__item__image}
              fluid={images[v.key].childImageSharp.fluid}
            />
          </div>
        )
      })}
    </div>
  )
}

export default Spaces
