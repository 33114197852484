import React, { useRef, useEffect, useState } from "react"
import { useWindowSize } from "../hooks/windowsize"
import Draggable from "react-draggable"
import Img from "gatsby-image"
import Icon from "@mdi/react"
import { mdiArrowExpandHorizontal } from "@mdi/js"
import gsap from "gsap"
import ImgCurtainStyle from "../styles/modules/imgcurtain.module.scss"

const ImgCurtain = ({ images }) => {
  const { width } = useWindowSize()
  const [state, setState] = useState(width / 2)

  const ref = useRef(null)
  const ball = useRef(null)
  const mask = useRef(null)
  const ballInner = useRef(null)
  const ballText = useRef(null)
  const draggable = useRef(null)

  const { nodes } = images

  useEffect(() => {
    gsap.set(ballInner.current, {
      scale: 0.5,
    })

    gsap.to(ballInner.current, {
      scale: 1,
      scrollTrigger: {
        trigger: ref.current,
        start: "top 20%",
        once: true,
      },
      ease: "bounce",
      duration: 1.5,
      onComplete: () => {
        gsap.to(ballText.current, {
          opacity: 1,
        })
      },
    })
  }, [])

  useEffect(() => {
    const ballbounds = ball.current.getBoundingClientRect()
    mask.current.style.width = width / 2 + ballbounds.width / 2 + "px"
    setState(width / 2)
    gsap.to(ball.current, {
      y: 0,
    })
  }, [width])

  const handleDrag = (e, ui) => {
    const bounds = ball.current.getBoundingClientRect()
    mask.current.style.width = bounds.x + bounds.width / 2 + "px"
    setState(ui.lastX)
  }

  const handleMove = e => {
    const bounds = ref.current.getBoundingClientRect()
    const ballbounds = ball.current.getBoundingClientRect()

    const y = e.clientY - bounds.top - ballbounds.height / 2

    if (
      y < (-1 * ballbounds.height) / 2 ||
      y > bounds.height - ballbounds.height / 2
    )
      return

    gsap.to(ball.current, {
      y: y,
    })
  }

  return (
    <div
      ref={ref}
      className={ImgCurtainStyle.imgcurtain}
      role="presentation"
      onMouseMove={handleMove}
    >
      {nodes.map((o, i) => {
        return (
          <div
            key={i}
            className={i ? ImgCurtainStyle.imgcurtain__mask : ""}
            ref={i ? mask : null}
          >
            <Img
              style={{
                width: width,
                maxWidth: width,
              }}
              key={i}
              fluid={o.childImageSharp.fluid}
            />
          </div>
        )
      })}
      <Draggable
        axis="x"
        onDrag={handleDrag}
        onStop={handleDrag}
        bounds="parent"
        position={{ x: state, y: 0 }}
        ref={draggable}
      >
        <div
          style={{
            width: width > 992 ? 88 : 44,
            position: "absolute",
            top: 0,
          }}
        >
          <div ref={ball} className={ImgCurtainStyle.imgcurtain__dragball}>
            <span
              ref={ballInner}
              className={ImgCurtainStyle.imgcurtain__dragball__inner}
            >
              <span
                ref={ballText}
                className={ImgCurtainStyle.imgcurtain__dragball__text}
              >
                {width > 992 ? (
                  "click & drag"
                ) : (
                  <Icon path={mdiArrowExpandHorizontal} color="#181717" />
                )}
              </span>
            </span>
          </div>
        </div>
      </Draggable>
    </div>
  )
}

export default ImgCurtain
