import React from "react"
import { useStaticQuery, graphql } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"
import CanvasGrid from "../components/canvas-grid"
import Carousel from "../components/carousel"
import AppTicker from "../components/appticker"
import { useTranslation } from "gatsby-plugin-react-i18next-nocookie"
import ImgCurtain from "../components/imgcurtain"
import Spaces from "../components/spaces"
import { LinkAnim } from "../components/link"
import Icon from "@mdi/react"
import { mdiArrowTopRight } from "@mdi/js"
import { LazyImage } from "../components/lazyImage"

import GIFPlaceholder from "../images/layouts_placeholder.jpg"
import GIF from "../images/layouts.gif"

import { mdiMapMarker, mdiPdfBox } from "@mdi/js"

import "../styles/pages/about.scss"

const AboutPage = () => {
  const { t, i18n } = useTranslation("about")

  const data = useStaticQuery(graphql`
    query {
      curtain: allFile(
        filter: { relativePath: { regex: "/about_(1|2).jpg/" } }
      ) {
        nodes {
          childImageSharp {
            fluid(maxWidth: 1920) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }

      pdfs: allFile(filter: { ext: { eq: ".pdf" } }) {
        edges {
          node {
            name
            relativePath
          }
        }
      }

      location: file(relativePath: { eq: "location.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 1920) {
            ...GatsbyImageSharpFluid
          }
        }
      }

      carouselImages: allFile(
        filter: { relativePath: { regex: "/about/carousel/" } }
      ) {
        nodes {
          childImageSharp {
            fluid(maxWidth: 1000) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    }
  `)

  const pdf = data.pdfs.edges
    .filter(o => {
      return o.node.name === "presentation_" + i18n.language
    })
    .pop()

  return (
    <Layout>
      <SEO
        title={t("metadata.title")}
        description={t("metadata.desc")}
        image="theloft.jpg"
      />

      <CanvasGrid class="canvas-grid--light" theme="light" />

      <div className="container-fluid sp-pb-9 bg--black page-intro">
        <div className="row">
          <div className="content text--sm offset-lg-2 col-lg-8 col-xl-6">
            <h1 className="title text--sm">{t("hero.title")}</h1>
            <p>{t("hero.content")}</p>
          </div>
        </div>
      </div>

      <div className="explore sp-pb-6">
        <div className="explore__items">
          <div className="explore__item col-12 col-sm-4">
            <div className="explore__item__title text--sm sp-mb-4">
              {t("facts.available_space")}
            </div>
            <div className="explore__item__desc text--md">115</div>
          </div>
          <div className="explore__item col-12 col-sm-4">
            <div className="explore__item__title text--sm sp-mb-4">
              {t("facts.max_capacity")}
            </div>
            <div className="explore__item__desc text--md">50</div>
          </div>
          <div className="explore__item col-12 col-sm-4">
            <div className="explore__item__title text--sm sp-mb-4">
              {t("facts.metro_walking")}
            </div>
            <div className="explore__item__desc text--md">5’</div>
          </div>
        </div>
      </div>

      <ImgCurtain images={data.curtain} />

      <div className="container-fluid bg--black sp-pt-9 sp-pb-9">
        <div className="row text-center">
          <div className="col-md-10 m-auto">
            <div className="title text--sm sp-mb-4">
              {t("unique_versatility.title")}
            </div>
            <div className="content text--lg sp-mb-7">
              {t("unique_versatility.desc")}
            </div>
          </div>
        </div>
      </div>

      <Spaces />

      <div className="container-fluid bg--black sp-pt-9 sp-pb-9">
        <div className="row text-center">
          <div className="col-md-10 m-auto">
            <div className="title text--sm sp-mb-4">
              {t("the_architectural_design.title")}
            </div>
            <div className="content text--lg sp-mb-7">
              {t("the_architectural_design.content")}
            </div>
            <a
              className="button button--dark button--icon sp-ml-2 sp-mr-2 sp-mb-4"
              download
              href={`/${pdf.node.name}.pdf`}
            >
              Download presentation
              <span className="icon">
                <Icon path={mdiPdfBox} />
              </span>
            </a>
          </div>
        </div>
      </div>
      <div className="sp-pb-9 bg--black">
        <LazyImage
          src={GIF}
          placeholder={GIFPlaceholder}
          aspect="56.25"
          alt="Otter dancing with a fish"
        />
      </div>
      <div className="explore explore--index sp-pb-9">
        <h3 className="text--lg explore__title sp-mb-7 text-center">
          {t("expore_the_possibilities.title")}
        </h3>
        <div className="explore__items">
          <LinkAnim className="explore__item col-12 col-sm-4" to="/events">
            <div className="explore__item__title text--sm sp-mb-4 d-flex align-items-center">
              <span className="flex-grow-1">
                {t("expore_the_possibilities.events_title")}
              </span>
              <Icon path={mdiArrowTopRight} size={1} />
            </div>
            <div className="explore__item__desc text--md sp-pb-8">
              {t("expore_the_possibilities.events_desc")}
            </div>
          </LinkAnim>
          <LinkAnim className="explore__item col-12 col-sm-4" to="/photoshoots">
            <div className="explore__item__title text--sm sp-mb-4 d-flex align-items-center">
              <span className="flex-grow-1">
                {t("expore_the_possibilities.photoshoots_title")}
              </span>
              <Icon path={mdiArrowTopRight} size={1} />
            </div>
            <div className="explore__item__desc text--md sp-pb-8">
              {t("expore_the_possibilities.photoshoots_desc")}
            </div>
          </LinkAnim>
          <LinkAnim className="explore__item col-12 col-sm-4" to="/gallery">
            <div className="explore__item__title text--sm sp-mb-4  d-flex align-items-center">
              <span className="flex-grow-1">
                {t("expore_the_possibilities.gallery_title")}
              </span>
              <Icon path={mdiArrowTopRight} size={1} />
            </div>
            <div className="explore__item__desc text--md sp-pb-8">
              {t("expore_the_possibilities.gallery_desc")}
            </div>
          </LinkAnim>
        </div>
      </div>

      <Carousel
        className="sp-pt-9"
        images={data.carouselImages.nodes}
        slidesPerView={3.5}
        spaceBetween={75}
        breakpoints={{
          320: {
            slidesPerView: 1.5,
            spaceBetween: 20,
          },
          992: {
            slidesPerView: 1.9,
            spaceBetween: 75,
          },
        }}
      />

      <div className="container-fluid sp-mt-9 sp-mb-9">
        <div className="row text-center">
          <div className="col-md-10 m-auto color--black">
            <div className="title text--sm sp-mb-4">
              {t("the_location.title")}
            </div>
            <div className="content text--lg sp-mb-7">
              {t("the_location.content")}
            </div>
            <a
              className="button button--light button--icon"
              href="https://goo.gl/maps/WpbqVuf4H6RLN77Y9"
              rel="noreferrer"
              target="_blank"
            >
              View in maps
              <span className="icon">
                <Icon path={mdiMapMarker} />
              </span>
            </a>
          </div>
        </div>
      </div>

      <AppTicker theme="light" />
    </Layout>
  )
}

export default AboutPage
